import React from 'react';
import { Link } from 'react-router-dom';
import video from '../../assets/video-keep-anon.mp4';
function Anonymous() {

  return (
    <>
      <h2 className="text-4xl font-bold mb-10 text-center">How do you keep me anonymous?</h2>
      
      <div className="flex justify-center mt-2">
        <video
          src={video}
          playsInline
          autoPlay
          muted
          loop
          className="max-h-[65vh] max-w-[80vw] object-contain"
        />
      </div>
      <p className="text-center font-bold text-l px-4 mb-4">
      <Link to="/privacy-policy" className="block text-lg text-black">
        Click here to view our privacy policy.
      </Link>
        
      </p>
    </>
  );
}

export default Anonymous;

