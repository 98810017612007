import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import PreregisterPage from './pages/PreregisterPage';
import CareersPage from './pages/CareersPage';
import AboutPage from './pages/AboutPage';
import TermsConditionsPage from './pages/TermsConditionsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ComingSoon from './pages/ComingSoon';
import './index.css';

function App() {
  return (
    <Router>
      <Header />
      <main className="py-8">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/preregister" element={<PreregisterPage />} />
	  <Route path="/careers" element={<CareersPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/terms-conditions" element={<TermsConditionsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
	  <Route path="/coming-soon" element={<ComingSoon />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;

