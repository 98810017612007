// HomePage.js

import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './carousel-tailwind.css';
import { CustomPrevArrow, CustomNextArrow } from './CustomArrows';
import HowMuch from './modals/HowMuch';
import Anonymous from './modals/Anonymous';
import MakeMoney from './modals/MakeMoney';
import ControlData from './modals/ControlData';
import ConnectData from './modals/ConnectData';
import video1 from '../assets/video1-introduction-h264.mp4';
import video2 from '../assets/video2-overview-h264.mp4';
import video3 from '../assets/video3-details-h264.mp4';
import { FullscreenOutlined } from '@ant-design/icons';
import { FaQuestionCircle } from 'react-icons/fa';

function HomePage() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const firstVideo = document.querySelector('.slick-current video');
    if (firstVideo) {
      firstVideo.currentTime = 0;
      firstVideo.playbackRate = 1.5;
      firstVideo.play().catch((error) => {
        console.log("Auto-play prevented: ", error);
      });
    }
  }, []);

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const enterFullScreen = () => {
    const currentSlideVideo = document.querySelector('.slick-current video');
    if (currentSlideVideo) {
      if (currentSlideVideo.requestFullscreen) {
        currentSlideVideo.requestFullscreen();
      } else if (currentSlideVideo.webkitEnterFullscreen) {
        currentSlideVideo.webkitEnterFullscreen();
      } else if (currentSlideVideo.webkitRequestFullscreen) {
        currentSlideVideo.webkitRequestFullscreen();
      } else if (currentSlideVideo.msRequestFullscreen) {
        currentSlideVideo.msRequestFullscreen();
      }
    }
  };

  const handleVideoEnd = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    beforeChange: (current, next) => {
      const videos = document.querySelectorAll('.slick-slide video');
      videos.forEach((video) => {
        video.pause();
        video.currentTime = 0;
      });
    },
    afterChange: (current) => {
      const currentSlideVideo = document.querySelector('.slick-current video');
      if (currentSlideVideo) {
        currentSlideVideo.currentTime = 0;
        currentSlideVideo.playbackRate = 1.5;
        currentSlideVideo.play().catch((error) => {
          console.log("Auto-play prevented on slide change: ", error);
        });
      }
    },
  };

  return (
    <div className="bg-white min-h-screen flex flex-col">
      {/* Video Carousel */}
      <div
        className="relative mx-auto bg-grey-100 w-full mb-[40px]"
        style={{
          maxWidth: '100vw',
          maxHeight: '100vh',
          aspectRatio: '16 / 9',
          overflow: 'visible',
          position: 'relative',
        }}
      >
        <Slider ref={sliderRef} {...sliderSettings}>
          {[video1, video2, video3].map((videoSrc, index) => (
            <div key={index} className="relative w-full h-full">
              <video
                src={videoSrc}
                muted
                playsInline
                loop={false}
                className="object-cover w-full h-full"
                onEnded={handleVideoEnd}
              />
              <button
                className="absolute top-3 right-3 text-white bg-gray-200 bg-opacity-75 rounded-full p-1 hover:bg-opacity-90"
                onClick={enterFullScreen}
                aria-label="Full Screen"
              >
                <FullscreenOutlined style={{ fontSize: '1.2rem', color: '#666' }} />
              </button>
            </div>
          ))}
        </Slider>
      </div>

      <hr className="border-t border-gray-300 w-full my-12" />

      {/* Updated "Common Questions" Section */}
      <section className="text-center my-8">
        <h2 className="text-4xl font-bold mb-6">Common Questions</h2>
        <div className="mx-auto bg-blue-50 rounded-lg p-4 shadow-sm inline-block">
          <ul className="space-y-4 text-left">
            <li className="flex items-center">
              <FaQuestionCircle className="text-blue-600 text-2xl mr-3" />
              <button
                onClick={() => openModal('makeMoney')}
                className="text-xl text-blue-600 hover:underline focus:outline-none font-semibold"
              >
                How can I make money?
              </button>
            </li>
            <li className="flex items-center">
              <FaQuestionCircle className="text-blue-600 text-2xl mr-3" />
              <button
                onClick={() => openModal('controlData')}
                className="text-xl text-blue-600 hover:underline focus:outline-none font-semibold"
              >
                How do I control my data?
              </button>
            </li>
            <li className="flex items-center">
              <FaQuestionCircle className="text-blue-600 text-2xl mr-3" />
              <button
                onClick={() => openModal('connectData')}
                className="text-xl text-blue-600 hover:underline focus:outline-none font-semibold"
              >
                How do I connect my data?
              </button>
            </li>
            <li className="flex items-center">
              <FaQuestionCircle className="text-blue-600 text-2xl mr-3" />
              <button
                onClick={() => openModal('anonymous')}
                className="text-xl text-blue-600 hover:underline focus:outline-none font-semibold"
              >
                How do you keep me anonymous?
              </button>
            </li>
          </ul>
        </div>
      </section>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div
            className="relative bg-white w-[90%] h-[90%] p-6 border-4 rounded-lg overflow-auto"
            style={{ borderColor: '#2b50aa' }}
          >
            <button onClick={closeModal} className="absolute top-4 right-4 text-2xl font-bold">
              X
            </button>
            {modalContent === 'howMuch' && <HowMuch />}
            {modalContent === 'makeMoney' && <MakeMoney />}
            {modalContent === 'controlData' && <ControlData />}
            {modalContent === 'connectData' && <ConnectData />}
            {modalContent === 'anonymous' && <Anonymous />}
          </div>
        </div>
      )}

      <section className="text-center">
        <div
          className="mx-auto mt-6 p-4 flex flex-col items-center"
          style={{ width: '90%', maxWidth: '510px' }}
        >
          <div className="mt-6 w-full">
            <button
              onClick={() => navigate('/preregister')}
              className="w-full max-w-[200px] h-[60px] bg-[rgb(43,80,170)] text-white text-lg sm:text-xl font-medium rounded-full px-6"
            >
              Pre-Register
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
