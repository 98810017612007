import { useState, useEffect } from 'react';
import axios from 'axios';

function PreregisterPage() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });

  const [formStatus, setFormStatus] = useState('');
  const [firstIncompleteField, setFirstIncompleteField] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [isSubmitting, setIsSubmitting] = useState(false); // Submission state for disabling the button

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Pulsing border effect for the first incomplete field
  useEffect(() => {
    const incompleteField = Object.entries(formData).find(
      ([, value]) => !value.trim()
    );

    if (incompleteField) {
      setFirstIncompleteField(incompleteField[0]);
    } else {
      setFirstIncompleteField(null);
    }
  }, [formData]);

  const getFieldClass = (fieldName) =>
    `w-full border ${
      firstIncompleteField === fieldName ? 'animate-pulse' : ''
    } border-black rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-green-500`;

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the "Send" button
    try {
      const response = await axios.post(
        'https://20o6s8q4ek.execute-api.us-east-2.amazonaws.com/prod/send-website-email',
        formData,
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (response.status === 200) {
        setIsModalOpen(true); // Show modal on success
      } else {
        setFormStatus('Failed to send the message.');
      }
    } catch (error) {
      console.error('Error:', error);
      setFormStatus('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false); // Re-enable the "Send" button
    }
  };

  // Close modal and redirect to the home page
  const handleModalClose = () => {
    setIsModalOpen(false);
    window.location.href = '/';
  };

  return (
    <div className="bg-white min-h-screen pt-[64px] p-8">
      {/* Header Section */}
      <section className="text-center mb-4">
        <h1
          className="font-poppins text-[55.24px] font-bold text-gray-800"
          style={{ fontStyle: 'normal' }}
        >
          Join our waitlist.
        </h1>
      </section>

      {/* Form Section */}
      <section className="max-w-2xl mx-auto">
        <form id="contact-form" className="space-y-6" onSubmit={handleSubmit}>
          <fieldset style={{ border: 'none', padding: '0' }}>
            <legend className="text-lg font-light mb-4">Name</legend>
            <div className="flex gap-6">
              <div className="flex-1">
                <label className="block text-left text-gray-700 mb-1">
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  className={getFieldClass('firstName')}
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="flex-1">
                <label className="block text-left text-gray-700 mb-1">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  className={getFieldClass('lastName')}
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </fieldset>

          <div>
            <label className="block text-left text-gray-700 mb-3">
              Email <span>(required)</span>
            </label>
            <p
              className="text-[14.6px] font-light font-poppins text-gray-500 mb-2"
              style={{ fontWeight: 300 }}
            >
              * Your identity is not our product. See Below.
            </p>
            <input
              type="email"
              name="email"
              className={getFieldClass('email')}
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>

          <div>
            <label className="block text-left text-gray-700 mb-1">Message</label>
            <textarea
              name="message"
              className={getFieldClass('message')}
              placeholder="What data would you like to monetize? ex: activities, workouts, spending, health, driving, shopping, browsing, sleep, other"
              rows="3"
              value={formData.message}
              onChange={handleInputChange}
              style={{
                fontWeight: 300,
                fontFamily: 'Poppins, sans-serif',
              }}
              required
            ></textarea>
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="w-[176px] h-[60px] bg-[rgb(43,80,170)] text-white rounded-full hover:bg-green-600 transition-all shadow-md"
              style={{
                fontSize: '26px',
                fontWeight: '500',
                fontFamily: 'Poppins',
              }}
              disabled={isSubmitting} // Disable when submitting
            >
              {isSubmitting ? 'Sending...' : 'Send'}
            </button>
          </div>
        </form>

        {/* Form Status */}
        {formStatus && (
          <p className="mt-4 text-center text-red-500">{formStatus}</p>
        )}
      </section>

      {/* Privacy Notice */}
      <section className="bg-white p-6 mt-4 rounded-md shadow-md max-w-2xl mx-auto border-2 border-black">
        <p
          className="text-black font-poppins text-[16px] font-bold text-center"
          style={{ whiteSpace: 'pre-line' }}
        >
          *We are committed to your privacy.
          <br/><br/>
          While we are asking for your email to add you to the waitlist, once you choose to join, we will delete your email. If you join, we only require that you create a handle and password.
          <br/><br/>
          Your identity is not our product.
        </p>
      </section>

      {/* Success Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm text-center">
            <h2 className="text-lg font-semibold mb-4">Thank You!</h2>
            <p className="text-gray-700 mb-6">Your pre-registration request has been received.</p>
            <button
              onClick={handleModalClose}
              className="w-[176px] h-[60px] bg-[rgb(43,80,170)] text-white rounded-full hover:bg-green-600 transition-all shadow-md"
              style={{
                fontSize: '20px',
                fontWeight: '500',
                fontFamily: 'Poppins',
              }}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default PreregisterPage;

