import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo-1713562472874-XCPYIANP17EG72L2R1JD.png';
import appStoreImage from '../assets/downloadOnAppStore.png'; // New PNG image
import qrCodeImage from '../assets/ncentai_qr.png'; // New QR code image
import instagramIcon from '../assets/instagramIcon.svg'; // Imported SVG

function Footer() {
  return (
    <footer className="bg-[#EBF1FF] py-8 text-black mt-8">
      <div className="px-6 mx-auto">
        <div className="grid grid-cols-2 gap-8 lg:grid-cols-4">
          {/* Logo and Download/QR Code Section */}
          <div className="col-span-2 space-y-4 lg:space-y-6">
            {/* nCent Logo */}
            <div className="mb-4">
              <a href="/">
                <img src={logo} alt="nCent Logo" className="w-[136px] h-[55px]" loading="lazy" />
              </a>
            </div>
            {/* Download and Social/QR Code Section */}
            <div className="flex items-start gap-6 mt-4">
              {/* Column with App Store Image and Instagram */}
              <div className="flex flex-col items-start space-y-4">
                {/* Download on the App Store Image */}
                <Link to="/coming-soon">
                  <img src={appStoreImage} alt="Download on the App Store" className="w-[130px] h-auto" />
                </Link>
                {/* Instagram Link */}
                <a
                  href="https://www.instagram.com/ncentme/"
                  className="text-black"
                  aria-label="Instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagramIcon} alt="Instagram" className="w-9 h-9" />
                </a>
              </div>

              {/* QR Code Image with Border */}
              <Link to="/coming-soon">
                <div className="w-[100px] h-[100px] border border-black">
                  <img src={qrCodeImage} alt="QR Code" className="w-full h-full object-cover" />
                </div>
              </Link>
            </div>
          </div>

          {/* Company Column */}
          <div>
            <h3 className="mb-4 text-2xl font-medium">Company</h3>
            <div className="space-y-2">
              <Link to="/careers" className="block text-lg text-black">
                Careers
              </Link>
              <Link to="/about" className="block text-lg text-black">
                About
              </Link>
            </div>
          </div>

          {/* Support Column */}
          <div>
            <h3 className="mb-4 text-2xl font-medium">Support</h3>
            <div className="space-y-2">
              <Link to="/terms-conditions" className="block text-lg text-black">
                Terms and Conditions
              </Link>
              <Link to="/privacy-policy" className="block text-lg text-black">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

