import React from 'react';
import { Link } from 'react-router-dom';

function ComingSoon() {
  return (
    <div className="bg-white min-h-screen pt-[64px] flex flex-col">
      <header className="text-center py-16">
        <h1 className="font-poppins font-bold text-4xl sm:text-5xl leading-normal sm:leading-relaxed text-black">
          Coming Soon
        </h1>
        <p className="mt-4 text-lg sm:text-xl text-gray-700 max-w-2xl mx-auto">
          Our nCent mobile app is not ready yet, but it will be available soon! Please check back later to download the app once it's ready.
        </p>
        <p className="mt-4 text-lg sm:text-xl text-gray-700 max-w-2xl mx-auto">
          In the meantime, click on the <Link to="/preregister" className="text-blue-600 underline">Preregister</Link> link at the top of the page to be notified as soon as we launch.
        </p>
      </header>
    </div>
  );
}

export default ComingSoon;

