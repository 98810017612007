import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

export const CustomPrevArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{
      ...style,
      position: 'absolute',
      top: '90%',
      left: 'calc(5px)', // Centered horizontally
      zIndex: 1,
      display: 'block',
    }}
    onClick={onClick}
  >
    <div className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full">
      <FaArrowLeft className="text-gray-400 text-2xl" />
    </div>
  </div>
);

export const CustomNextArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{
      ...style,
      position: 'absolute',
      top: '90%',
      right: 'calc(25px)', // Centered horizontally
      zIndex: 1,
      display: 'block',
    }}
    onClick={onClick}
  >
    <div className="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-full">
      <FaArrowRight className="text-gray-400 text-2xl" />
    </div>
  </div>
);

