import React from 'react';

function AboutPage() {
  return (
    <div className="bg-white min-h-screen pt-[64px] flex flex-col">
      {/* Placeholder content */}
      <header className="text-center py-16">
        <h1 className="font-poppins font-bold text-3xl sm:text-4xl lg:text-5xl leading-normal sm:leading-relaxed text-black">
          About Page Placeholder
        </h1>
      </header>

    </div>
  );
}

export default AboutPage;

