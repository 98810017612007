import { useEffect } from 'react';

function PrivacyPolicyPage() {

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white min-h-screen pt-[64px] flex flex-col">
      {/* Privacy Policy Content */}
      <header className="text-center py-16">
        <h1 className="font-poppins font-bold text-3xl sm:text-4xl lg:text-5xl leading-normal sm:leading-relaxed text-black">
          nCent Privacy Policy
        </h1>
      </header>
      <main className="px-6 sm:px-12 lg:px-24 py-8 flex-grow">
        <section>
          <p>
            At nCent, we prioritize your privacy and give you full control over your data. Our platform is designed to
            let you interact anonymously and benefit directly from your insights, without revealing your identity.
            Here’s how we ensure your privacy at every step.
          </p>
        </section>

        <section className="mt-8">
          <h2 className="font-semibold text-2xl sm:text-3xl text-black">1. We Respect Your Privacy</h2>
          <p className="mt-4">
            nCent never asks for contact information or personal details that could identify you. You create an
            anonymous handle and password on the platform.
          </p>
          <p className="mt-4">
            When a brand buys your insights or interacts with you, we create an ID that is unique to you and that
            specific brand. This is the only ID associated with you in relation to that brand, ensuring that your
            privacy is preserved separately for each brand.
          </p>
          <p className="mt-4">
            When you accept an offer from a brand, any personal details you provide to that brand (such as shipping or
            payment info) stay with the brand. nCent only receives confirmation that your brand-specific ID accepted an
            offer, so we can issue your revenue share for accepting that offer.
          </p>
          <p className="mt-4">
            For payments, we work with trusted third-party partners who handle identity verification. Your personal
            information remains solely with the payment partner and is never linked to your nCent account.
          </p>
          <p className="mt-4">
            If you choose to participate in our Anyfluencer program and promote a brand on social media, you must
            request a one-time token for that for each influence post. This token, used with #ncentMe, lets us log your
            influence anonymously. Once we find that token in social, we associate you to the reward pool for that brand
            and delete that token from our platform. Once the influenced brand joins nCent, you receive a portion of the
            reward pool for your assistance in bringing the brand onboard.
          </p>
        </section>

        <section className="mt-8">
          <h2 className="font-semibold text-2xl sm:text-3xl text-black">2. Data Sharing on Your Terms</h2>
          <p className="mt-4">
            The data you choose to share is anonymized and tied only to your nCent handle. You decide which insights and
            data points to share and can turn sharing on or off at any time. Insights are specific to you, timely, and
            relevant—brands see only the data you’ve agreed to share specifically with them.
          </p>
          <p className="mt-4">
            Once an insight is sold to a brand, the transaction is final. You cannot withdraw the insight after the
            sale, but the sale remains anonymous. Since insights are timely, the insight this week can be very different
            from the insight measurements the next week. This means your insights are valuable to both sell and to offer
            for subscription so that the brand can better understand your specific needs.
          </p>
          <p className="mt-4">
            Each consumer-brand interaction is managed through your unique brand-specific ID, ensuring no personal
            information is exchanged.
          </p>
        </section>

        <section className="mt-8">
          <h2 className="font-semibold text-2xl sm:text-3xl text-black">3. Security and Data Minimization</h2>
          <p className="mt-4">
            nCent uses strong encryption and industry-standard security practices to protect your data. We follow strict
            data retention policies, deleting temporary tokens and unnecessary data as soon as they’re no longer needed.
            Regular security audits help ensure that our system stays secure.
          </p>
          <p className="mt-4">
            Our team’s philosophy is simple: the best way to protect your data is to avoid collecting personal details.
            With nCent, you can confidently share and earn from your data, knowing your identity remains protected.
          </p>
        </section>

        <section className="mt-8">
          <h2 className="font-semibold text-2xl sm:text-3xl text-black">Contact Us</h2>
          <p className="mt-4">
            For questions about this policy or your data, please contact our support team at{' '}
            <a href="mailto:policy@nCent.me" className="text-blue-600 underline">
              policy@nCent.me
            </a>
            .
          </p>
        </section>
      </main>

    </div>
  );
}

export default PrivacyPolicyPage;

