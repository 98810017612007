import React from 'react';
import video from '../../assets/video-connecting-data.mp4';
function ConnectData() {

  return (
    <>
      <h2 className="text-4xl font-bold mb-10 text-center">How do I connect my data?</h2>
  
      <div className="flex justify-center mt-2">
        <video
          src={video}
          playsInline
          autoPlay
          muted
          loop
          className="max-h-[65vh] max-w-[80vw] object-contain"
        />
      </div>
    </>
  );
}

export default ConnectData;

