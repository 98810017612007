import React from 'react';
import screenshot2 from '../../assets/Screenshot-1713562464483-UQZ307WDU2YY4QQADK7A.jpg';
import screenshot3 from '../../assets/Screenshot-1713562464488-9VLXNS7E3HQDNG1C6QBJ.jpg';
function HowMuch() {

  return (
    <>
        <h2 className="text-4xl font-bold mb-4 text-center">How much can I make?</h2>
        <div className="flex flex-col items-center overflow-y-auto max-h-[70vh]">
            <img src={screenshot2} alt="Estimate 1" className="w-[90%] mb-4" />
            <img src={screenshot3} alt="Estimate 2" className="w-[90%]" />
        </div>
        <p className="mt-4 text-center text-gray-600 text-lg">
            All calculations are estimates based upon market assumptions and AI assistance.
        </p>
    </>
  );
}

export default HowMuch;

