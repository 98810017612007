import { useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/nCentLogoWhite.png';

function Header() {
  const [showBuyerLink, setShowBuyerLink] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const location = useLocation();
  const timerRef = useRef(null);

  const navLinkClass = 'text-[16px] lg:text-[14px] xl:text-[16px] font-light font-poppins text-white leading-[28.8px] hover:text-blue-300 relative';
  const activeLinkClass = 'underline-active';

  const handleLogoClick = () => {
    if (!showBuyerLink) {
      setClickCount((prevCount) => prevCount + 1);

      if (clickCount === 0) {
        timerRef.current = setTimeout(() => {
          setClickCount(0);
        }, 5000);
      }

      if (clickCount + 1 === 8) {
        setShowBuyerLink(true);
        clearTimeout(timerRef.current);
      }
    }
  };

  return (
    <header className="w-full bg-[#2B50AA] border-b border-black p-4">
      <div className="flex items-center justify-between max-w-[1200px] mx-auto">
        {/* Left Side Links */}
        <div className="flex items-center space-x-4 flex-grow justify-start">
          <Link
            to="/"
            className={`${navLinkClass} ${location.pathname === '/' ? activeLinkClass : ''}`}
          >
            Home
          </Link>

          {/* Pre-Register link, displayed on the left if screen width > 550px */}
          <Link
            to="/preregister"
            className={`hidden sm:inline-block ${navLinkClass} ${location.pathname === '/preregister' ? activeLinkClass : ''}`}
          >
            Pre-Register
          </Link>
        </div>

        {/* Center Logo */}
        <div className="flex justify-center flex-none">
          <img
            src={logo}
            alt="nCent Logo"
            className="w-[160px] h-[60px]"
            onClick={handleLogoClick}
          />
        </div>

        {/* Right Side Links */}
        <div className="flex items-center space-x-4 flex-grow justify-end">
          {/* Pre-Register link, displayed on the right for screens < 550px */}
          <Link
            to="/preregister"
            className={`sm:hidden ${navLinkClass} ${location.pathname === '/preregister' ? activeLinkClass : ''}`}
          >
            Pre-Register
          </Link>

          {/* Buyer Portal link, shown conditionally based on screen size */}
          {showBuyerLink && (
            <a
              href="https://buyer.ncent.ai"
              target="_blank"
              rel="noopener noreferrer"
              className={navLinkClass}
            >
              Buyer Portal
            </a>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
